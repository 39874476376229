import cfg from "./combs-media-config.json";

export function loadEnvironmentConfig() {
  type HostEnvironmentMapKey = keyof typeof cfg.hostEnvironmentMap;
  type HostEnvironmentMapValue =
    (typeof cfg.hostEnvironmentMap)[HostEnvironmentMapKey];
  type EnvironmentsKey = keyof typeof cfg.environments;
  const hostname = window.location.hostname as HostEnvironmentMapKey;
  const environment = cfg.hostEnvironmentMap[
    hostname
  ] as HostEnvironmentMapValue;
  const environmentConfig = cfg.environments[environment as EnvironmentsKey]; // TODO: think about zod for a schema here

  return environmentConfig;
}
