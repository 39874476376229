import { CombsNav } from "./CombsNav";
import { loadEnvironmentConfig } from "./EnvironmentConfig";

function Tips() {
  const environmentConfig = loadEnvironmentConfig();
  return (
    <div className="bg-white dark:bg-slate-800">
      <header className="absolute inset-x-0 top-0 z-50">
        <CombsNav />
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-6xl">
              Tips
            </h1>
          </div>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
            You can reward previous work we've done for you and contribute to
            the future success of Combs Media. Tips for Combs Media will be used
            to grow our business and are NOT tax-deductible.
          </p>
          <div className="text-center">
            <p className="leading-8">
              <a
                href={environmentConfig.tips.href}
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Leave a Tip
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tips;
