import { CombsNav } from "./CombsNav";

function SoftwareDevelopment() {
  return (
    <div className="bg-white dark:bg-slate-800">
      <header className="absolute inset-x-0 top-0 z-50">
        <CombsNav />
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-6xl">
              Software Development
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-400">
              We offer software development services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoftwareDevelopment;
