import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SoftwareDevelopment from "./SoftwareDevelopment";
import LiveStreaming from "./LiveStreaming";
import DronePhotography from "./DronePhotography";
import Company from "./Company";
import Tips from "./Tips";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/software-development",
    element: <SoftwareDevelopment />,
  },
  {
    path: "/live-streaming",
    element: <LiveStreaming />,
  },
  {
    path: "/drone-photography",
    element: <DronePhotography />,
  },
  {
    path: "/company",
    element: <Company />,
  },
  {
    path: "/tips",
    element: <Tips />,
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
